import React, { useEffect } from 'react';

import { useDomain, useIdentitySiteUrls } from '@netfront/gelada-identity-library';

function ProjectConsolePage() {
  const { isDomainReady } = useDomain();
  const { getBaseUrl: identityBaseUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    const identityUrl = `${identityBaseUrl()}/dashboard`;

    window.location.href = identityUrl;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  return <></>;
}

export default ProjectConsolePage;
